import useCmsPage from '~/client/shared/cms/CmsPage/useCmsPage';
import useGameCardSize from '~/client/shared/game/GameCard/useGameCardSize';
import GameCarousel from '~/client/shared/game/GameCarousel';
import Lazy from '~/client/shared/LazyLoad/Lazy';
import CarouselPlaceholder from '~/client/shared/LazyLoad/Placeholder/CaroulselPlaceholder';
import SourceGameSliderType from '@/client/helpers/trackEvent/umamiEvents/types/SourceGameSliderType';

type GameProps = {
  titleId: string;
  cmsHandle: string;
  sliderName: SourceGameSliderType;
};

const KoreaTopGamesSection: FC<GameProps> = ({
  titleId,
  cmsHandle,
  sliderName,
}) => {
  const { width, height } = useGameCardSize(true);
  const { data } = useCmsPage({
    handle: cmsHandle,
    section: 'games',
  });

  const handle =
    data?.content?.pages?.edges?.[0]?.node?.mainBlockPlainText || '';

  const placeholder = (
    <CarouselPlaceholder
      width={width}
      height={height}
      length={6}
      titleId={titleId}
    />
  );

  return (
    <Lazy placeholder={placeholder}>
      <GameCarousel
        big
        handle={handle}
        titleId={titleId}
        placeholder={placeholder}
        sourcePage="front_page"
        sliderName={sliderName}
      />
    </Lazy>
  );
};

export default KoreaTopGamesSection;
