import useFeatures from '~/client/hooks/feature/useFeatures';
import KoreaTopGamesSection from './KoreaTopGamesSection';
import SourceGameSliderType from '@/client/helpers/trackEvent/umamiEvents/types/SourceGameSliderType';

type GameProps = {
  titleId: string;
  cmsHandle: string;
  sliderName: SourceGameSliderType;
  featureFlag: string;
};

const games: GameProps[] = [
  {
    titleId: 'general.top_live_baccarat_games',
    cmsHandle: 'korea-top-live-baccarat',
    sliderName: 'live_baccarat',
    featureFlag: 'KoreaTopBaccarat',
  },
  {
    titleId: 'general.top_live_roulette_game',
    cmsHandle: 'korea-top-roulette',
    sliderName: 'roulette',
    featureFlag: 'KoreaTopRoulette',
  },
  {
    titleId: 'general.top_live_blackjack_games',
    cmsHandle: 'korea-top-blackjack',
    sliderName: 'blackjack',
    featureFlag: 'KoreaTopBlackjack',
  },
  {
    titleId: 'general.top_video_slots_game',
    cmsHandle: 'korea-top-games',
    sliderName: 'game_show',
    featureFlag: 'KoreaTopVideoSlots',
  },
];

const KoreaTopGames = () => {
  const features = useFeatures();

  return (
    <div className="flex flex-col gap-8">
      {games.map((game) => {
        const isEnabled = features[game.featureFlag];
        if (!isEnabled) {
          return null;
        }
        return <KoreaTopGamesSection key={game.titleId} {...game} />;
      })}
    </div>
  );
};

export default KoreaTopGames;
